@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

#root,
.app {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-family: "Roboto", "Helvetica", sans-serif;
}

ul,
nav,
p {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
}

#root {
  display: flex;
  height: 100%;
  white-space: pre-line;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapper,
.content {
  flex-grow: 1;
  position: relative;
}

article {
  padding: 2rem 0;
}
